// src/Backdrop.js
import React from 'react';
import './Backdrop.css'
import loop from './videos/loop1.mp4';

const Backdrop = () => {
  return (
<video id='video' autoPlay loop muted>
    <source src={loop} type='video/mp4' />
</video>
  );
};

export default Backdrop;
