import React from 'react';
import { Link } from 'react-router-dom';

import './Menu.css'

const Menu = () => {
  return (
    <div className="Menu-bar">
<Link className="Menu-link" to="/">HOME</Link>
/
<Link className="Menu-link" to="/about">ABOUT</Link>
/
<Link className="Menu-link" to="/music">MUSIC</Link>
    </div>
  );
};

export default Menu;