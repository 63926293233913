// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';
import './App.css'
import Backdrop from './Backdrop';
import About from './About';
import Menu from './Menu';
import Music from './Music'

const App = () => {
  return (
    <Router>
      <div className="App">
        <div className="App-overlay"></div>
        <Backdrop />
        <div className="App-header">
            SCAN DRAXLEY
        </div>
        <Menu />
        <div className="App-content">
        <Routes>
          <Route path="/about" Component={About} />
          <Route path="/music" Component={Music} />
        </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
