import React from 'react';
import './About.css'

const About = () => {
  return (
    <div className="App-content">
Scan Draxley is a UK-based composer and multi-instrumentalist renowned for his captivating post-rock compositions. Since the 1980s, he has been crafting intricate soundscapes, both independently and in collaboration with other artists. His music often delves into themes of introspection, nostalgia, and the natural world, evoking a wide range of emotions through its rich textures, evocative melodies, and immersive soundscapes.
</div>
  );
};

export default About;