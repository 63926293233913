import React from 'react';
import './Music.css'

const Music = () => {
  return (
    <div className="App-content">
<iframe title="embed1" style={{border: 0, width: '100%', height: '120px'}} 
src="https://bandcamp.com/EmbeddedPlayer/track=400980129/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/artwork=small/transparent=true/" seamless><a href="https://scandraxley.bandcamp.com/track/letter-27">Letter 27 by Scan Draxley</a></iframe>
</div>
  );
};

export default Music;